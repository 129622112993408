import React, { useState } from 'react';
import cn from 'clsx';
import PropTypes from 'prop-types';
import isEmail from 'validator/lib/isEmail';
import axios from 'axios';

const JoinWaitlist = ({ toggleWaitlistModal, showWaitlistModal }) => {
  const [input, setInput] = useState({
    email: '',
    companyName: '',
    agents: '',
  });
  const [sending, setSending] = useState(false);
  const [success, setSuccess] = useState(false);

  const inputHandler = ({ target: { name, value } }) => {
    setInput((state) => ({ ...state, [name]: value }));
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (
      input.companyName.length > 0 &&
      input.email.length > 0 &&
      input.agents >= 1 &&
      isEmail(input.email)
    ) {
      setSending(true);
    }
    (async () => {
      try {
        /* eslint-disable indent */
        await axios.post(`https://web.ruttl.com/appmain/join-waitlist`, {
          companyName: input.companyName,
          email: input.email,
          agents: input.agents,
        });
        /* eslint-enable indent */
        setInput({
          email: '',
          companyName: '',
          agents: '',
        });

        setSuccess(true);
      } catch (error) {
        /* do nothing */
      } finally {
        setSending(false);
      }
    })();
  };

  return (
    <div
      id="waitlist-modal"
      className={cn(['contact-us-modal', { show: showWaitlistModal }])}>
      <div className="contact-us-container">
        <button
          type="button"
          aria-label="close"
          className="button-unstyled close-button"
          onClick={() => toggleWaitlistModal()}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path
              fill="none"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="32"
              d="M368 368L144 144M368 144L144 368"
            />
          </svg>
        </button>
        {!success ? (
          <div>
            <h3 style={{ marginBottom: 20 }}>Join the waitlist</h3>
            <form
              onSubmit={submitHandler}
              className="users-form smaller-labels">
              <div className="mb-2">
                <label htmlFor="name">
                  Company Name
                  <br />
                  <input
                    type="text"
                    name="companyName"
                    placeholder="Enter name"
                    value={input.companyName}
                    onChange={inputHandler}
                    required
                  />
                </label>
              </div>
              <div className="mb-2">
                <label htmlFor="contact-email">
                  Work Email Address
                  <br />
                  <input
                    type="email"
                    name="email"
                    id="contact-email"
                    placeholder="Enter email address"
                    value={input.email}
                    onChange={inputHandler}
                    required
                  />
                </label>
              </div>
              <div className="mb-2">
                <label htmlFor="contact-email">
                  What number of agents would you require?
                  <span className="pop-info">
                    <svg width="15px" viewBox="0 0 7.6 7.6">
                      <path
                        d="M7.6 3.8c0 2.1-1.7 3.8-3.8 3.8S0 5.9 0 3.8C0 1.7 1.7 0 3.8 0s3.8 1.7 3.8 3.8zm-7.1 0C.5 5.6 2 7.1 3.8 7.1c1.8 0 3.3-1.5 3.3-3.3C7.1 2 5.6.5 3.8.5 2 .5.5 2 .5 3.8zm2.9 1.5V3.4h-.2c-.1 0-.2-.1-.2-.2v-.1c0-.1.1-.2.2-.2H4c.1 0 .2.1.2.2v2.1h.2c.1 0 .2.1.2.2v.1c0 .1-.1.2-.2.2H3.2c-.1 0-.2-.1-.2-.2v-.1c0-.1.1-.2.2-.2h.2zm.9-3.2c0 .3-.2.5-.5.5s-.5-.2-.5-.5.2-.5.5-.5.5.2.5.5z"
                        fill="#555"
                      />
                    </svg>
                    <span className="info-text">
                      Frontline support agents who communicate with your users
                      in real-time.
                    </span>
                  </span>
                  <br />
                  <input
                    type="number"
                    name="agents"
                    placeholder="Enter the number of agents"
                    value={input.agents}
                    min={1}
                    onChange={inputHandler}
                    required
                    style={{
                      fontSize: 14,
                      padding: '19px 14px',
                    }}
                  />
                </label>
              </div>
              <button
                type="submit"
                className="submit-button"
                style={{ padding: '14px 40px', fontSize: 16 }}
                disabled={
                  sending === true ||
                  !input.companyName.length ||
                  !input.email.length ||
                  !(input.agents >= 1) ||
                  !isEmail(input.email)
                }>
                {sending ? 'Sending...' : 'Submit'}
              </button>
            </form>
          </div>
        ) : (
          <div>
            <div className="success-head-img">
              <img src="/assets/img/tick-mark.svg" alt="tick icon" />
            </div>
            <div>
              We&apos;ve registered your interest, and will reach out once we
              launch customer feedback.
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
JoinWaitlist.propTypes = {
  toggleWaitlistModal: PropTypes.func,
  showWaitlistModal: PropTypes.bool,
};

export default JoinWaitlist;
